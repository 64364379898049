<template>
  <div class="home">
    <div class="question-list">
      <div v-for="(item, index) in list" :key="item.id" class="question-one" @click="goDetail(item.question_id)">
        <div class="q-index">
          {{ index + 1 }}. 
        </div>
        <div class="q-content">
          {{ item.question }}
        </div>
        <div v-if="item.answer_path" class="q-right">
          <van-tag type="success" size="large">已回答</van-tag>
        </div>
        <div v-else class="q-right">
          <van-tag type="danger" size="large">未回答</van-tag>
        </div>
        <van-icon name="arrow" />
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Home',
  components: {
  },
  data: () => {
    return {
      list: [],
      answerPeople: 1,
    }
  },
  mounted: function () {
    this.answerPeople = this.$route.query.pid || 1
    this.getList()
  },
  methods: {
    getList() {
      this.$req({
        url: 'question/list',
        data: {
          answer_people: this.answerPeople,
        },
        callback: (data) => {
          console.log(data)
          console.log(123)
          this.list = data.list || []
        }
      })

    },

    goDetail(qid) {
      console.log(qid)
      this.$router.push({
        name: 'Answer',
        query: { question_id: qid }
      })
    }
  }
}
</script>

<style lang="less">
.question-list {
  text-align: left;
  padding: 16px 12px;
  font-size: 18px;
  .question-one {
    border-bottom: 1px solid #DBDBDB;
    cursor: pointer;
    padding: 12px 0;
    height: auto;
    line-height: 26px;
    display: flex;
    justify-content: center;
    justify-items: center;
    align-items: center;
    .q-content {
      flex: 1;
      margin-left: 8px;
    }
    .q-right {
      margin: 0 8px;
    }
  }
}
</style>